
import { Image, Text, View, Pressable } from 'react-native';
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { refetchStreamAtom, streamFlowClientAtom, wormholeStreamIdForLad, wormholeVestingAccountAtom } from '../_atoms/wormholeVestingAccountAtom';
import { isRestrictedLocationAtom } from '../_atoms/geofenceAtom';

// @ts-ignore
import wLogo from "../../assets/$w-logo.png"
import { getStakeApi } from '../_utils/stakeApi';
import { pubkeyAtom } from '../_atoms/userNftsAtom';
import { currentMadLadAtom } from '../_atoms/currentMadladAtom';
import { PublicKey } from '@solana/web3.js';
import { getBN } from '@streamflow/stream';
import { confirmTxSignature } from '../_utils/confirmTxSignature';
import { Suspense, useState } from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { VESTING_TOKEN_DECIMALS } from '../_participants/wormholeDropStreams';


export default function WormholeAccountScreen({ navigation }) {

  return <Suspense fallback={<Fallback />}>
    <Container navigation={navigation} />
  </Suspense>
}

function Fallback() {
  return (
    <View
      className="absolute top-0 bottom-0 left-0 justify-center items-center right-0 bg-black bg-opacity-10 bg-gradient-to-br from-[#525252] to-[#1F1F1F]"
    >
      <Image
        style={{
          width: "100px",
          height: "100px"
        }}
        source={{
          uri: wLogo
        }}
      />

    </View>
  )
}

function Container({ navigation }) {
  const publicKey = useRecoilValue(pubkeyAtom);
  const streamFlowClient = useRecoilValue(streamFlowClientAtom);
  const currentMadLad = useRecoilValue(currentMadLadAtom);
  const ladStreamId = useRecoilValue(wormholeStreamIdForLad(currentMadLad.account.mint))
  const vestingAccountLoadable = useRecoilValueLoadable(wormholeVestingAccountAtom);
  const refetchStream = useSetRecoilState(refetchStreamAtom);
  const isRestrictedLocationLoadable = useRecoilValueLoadable(isRestrictedLocationAtom);

  const vestingAccount = vestingAccountLoadable.getValue();
  const isRestrictedLocation = isRestrictedLocationLoadable.getValue();

  const [isConfirming, setIsConfirming] = useState(false);
  const [error, setError] = useState(null);

  const onClaim = async () => {
    try {
      setError(null)
      const stakeApi = await getStakeApi();
      const tx = await stakeApi.claimStreamFlowTokens({
        streamFlowClient,
        amount: getBN(Math.floor(vestingAccount.claimable), VESTING_TOKEN_DECIMALS),
        streamId: ladStreamId,
        user: new PublicKey(publicKey),
        nft: new PublicKey(currentMadLad.account.mint)
      });
      setIsConfirming(true);
      await confirmTxSignature(tx, "confirmed");
      refetchStream(Math.random() + "");
      setIsConfirming(false);
    } catch (e) {
      setIsConfirming(false);
      setError(e?.message ?? "Something went wrong, try again.")
    }
  };

  if (!vestingAccount) {
    return <Fallback />
  }

  const fullyVested = vestingAccount.end.getTime() < Date.now();
  const claimButtonDisabled = vestingAccount.claimable <= 0 || isRestrictedLocation
  const fullyClaimed = vestingAccount.totalClaimed >= vestingAccount.totalAllocation;

  const days = Math.floor(vestingAccount.secondsToNext / (60 * 60 * 24));
  const daysStr = ("00" + Math.floor(vestingAccount.secondsToNext / (60 * 60 * 24))).substr(-2);
  const hours = "00" + Math.floor((vestingAccount.secondsToNext % (60 * 60 * 24)) / (60 * 60));
  const min = "00" + Math.floor((vestingAccount.secondsToNext % (60 * 60)) / (60));
  const sec = "00" + Math.floor(vestingAccount.secondsToNext % (60));

  return (
    <View
      className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-10 bg-gradient-to-br from-[#525252] to-[#1F1F1F]"
    >
      <View className="flex w-full h-[54px] justify-center">
        <Text className="text-white text-center mt-[6px] font-inter-bold">{`$W`}</Text>
      </View>
      <View
        className="absolute top-[54px] right-[16px] left-[16px] bottom-[12px] justify-between"
      >
        {!fullyClaimed ? (
          <>
            <View className="w-full m-[3] _bg-black bg-opacity-10 _backdrop-blur-2xl _px-4 _py-3 _rounded-2xl gap-[20px]">
              <Text className="text-[#f0f0f0] font-inter-medium tracking-wide text-md uppercase">{`Claimable`}</Text>
              <View className="flex-row justify-start items-center gap-[8px] pl-[24px]">
                <Image
                  style={{
                    width: "48px",
                    height: "48px"
                  }}
                  source={{
                    uri: wLogo
                  }}
                />
                <View>
                  <Text className="text-white font-inter-bold text-6xl py-3">{`${Math.floor(vestingAccount.claimable)}`}</Text>
                </View>
              </View>
              {
                isRestrictedLocation ? (
                  <View
                    className="bg-black w-full justify-center rounded-lg p-4"
                  >
                    <Text className="text-white font-inter-medium py-3">{`Airdrop unavailable in your region.`}</Text>
                  </View>
                ) : (
                  <View>
                    <Pressable
                      disabled={claimButtonDisabled}
                      className={`flex-row gap-3 bg-black w-full justify-center items-center rounded-lg p-4 hover:opacity-[0.8]`}
                      onPress={onClaim}

                    >
                      <Text className="text-white font-inter-bold text-center">{isConfirming ? "Confirming" : "Claim now"}</Text>
                      {isConfirming ? (
                        <View
                          className="animate-spin relative"
                        >
                          <MaterialIcons
                            name="autorenew"
                            size={22}
                            color="white"
                          />
                        </View>
                      ) : null}

                    </Pressable>

                    <View className="p-[5px]  h-[20px]">
                      {error ? (
                        <Text className="text-[black] font-xs font-inter-light text-left">{`Error: ${error}`}</Text>
                      ) : (null)}
                    </View>
                  </View>
                )}
            </View>
          </>
        ) : (
          <View className="flex-row justify-center items-center pb-[32px]">
            <Image
              style={{
                width: "100px",
                height: "100px"
              }}
              source={{
                uri: wLogo
              }}
            />
          </View>
        )
        }



        {
          fullyVested ? (
            null
          ) : (
            <View className="w-full m-[3] _bg-black bg-opacity-10 _backdrop-blur-2xl _px-4 _py-3 _rounded-2xl gap-[20px]">
              <Text className="text-[#f0f0f0] font-inter-medium tracking-wide text-md uppercase">{`Next unlock`}</Text>
              <View className="flex-row gap-[4px] pl-[24px]">
                {days > 0 ? (
                  <>
                    <Text className="text-white text-2xl font-inter-bold">{`${daysStr.substr(-2)}`}</Text>
                    <Text className="text-[#f0f0f0] text-2xl font-inter-light pr-[8px]">{`d`}</Text>
                  </>
                ) : null}
                <Text className="text-white text-2xl font-inter-bold">{`${hours.substr(-2)}`}</Text>
                <Text className="text-[#f0f0f0] text-2xl font-inter-light pr-[8px]">{`h`}</Text>
                <Text className="text-white text-2xl font-inter-bold">{`${min.substr(-2)}`}</Text>
                <Text className="text-[#f0f0f0] text-2xl font-inter-light pr-[8px]">{`m`}</Text>
                <Text className="text-white text-2xl font-inter-bold">{`${sec.substr(-2)}`}</Text>
                <Text className="text-[#f0f0f0] text-2xl font-inter-light pr-[8px]">{`s`}</Text>
              </View>
            </View>
          )
        }

        <View className="w-full _bg-black bg-opacity-10 _backdrop-blur-2xl _px-4 _py-3 _rounded-2xl gap-[20px]">
          <Text className="text-[#f0f0f0] font-inter-medium tracking-wide text-md uppercase">{`Wormhole Airdrop`}</Text>
          {/* <Text className="text-white font-inter-medium py-3`}</Text> */}
          <View className="pl-[24px] gap-[8px]">
            <View className="flex-row">
              <View className="w-[35%]">
                <Text className="text-[#f0f0f0] font-inter-medium text-sm">{`Total`}</Text>
              </View>
              <View className="flex-row justify-start items-center gap-[8px]">
                <Image
                  style={{
                    width: "14px",
                    height: "14px"
                  }}
                  source={{
                    uri: wLogo
                  }}
                />
                <View>
                  <Text className="text-white font-inter-bold text-sm">{`${vestingAccount.totalAllocation}`}</Text>
                </View>
              </View>
            </View>
            <View className="flex-row">
              <View className="w-[35%]">
                <Text className="text-[#f0f0f0] font-inter-medium text-sm">{`Claimed`}</Text>
              </View>
              <View className="flex-row justify-start items-center gap-[8px]">
                <Image
                  style={{
                    width: "14px",
                    height: "14px"
                  }}
                  source={{
                    uri: wLogo
                  }}
                />
                <View>
                  <Text className="text-white font-inter-bold text-sm">{`${vestingAccount.totalClaimed}`}</Text>
                </View>
              </View>
            </View>
            <View className="flex-row">
              <View className="w-[35%]">
                <Text className="text-[#f0f0f0] font-inter-medium text-sm">{`Start`}</Text>
              </View>
              <Text className="text-white font-inter-bold text-sm">{`${vestingAccount.start.toDateString()}`}</Text>
            </View>
            <View className="flex-row">
              <View className="w-[35%]">
                <Text className="text-white  font-inter-medium text-sm">{`End`}</Text>
              </View>
              <Text className="text-white font-inter-bold text-sm">{`${vestingAccount.end.toDateString()}`}</Text>
            </View>
          </View>
        </View >
        <View className="w-full _bg-black bg-opacity-20 _backdrop-blur-2xl _px-4 _py-3 _rounded-2xl gap-[20px] justify-center">

          {/* <View className="flex-row pl-[24px] opacity-80">
            <View className="w-[65%] flex-row">
              <Text className="text-[#f0f0f0] font-inter-medium text-xs">{`Powered by `}</Text>
              <Text className="text-[#f0f0f0] font-inter-bold text-xs">{`Streamflow`}</Text>
            </View>
            <View className="flex-row justify-start items-center">
            </View>
          </View> */}
          <View className="flex-row justify-end opacity-70">
            <Text className="text-[#f0f0f0] font-inter-light text-xs">{`Powered by `}</Text>
            <Text className="text-[#f0f0f0] font-inter-bold text-xs">{`Streamflow`}</Text>
          </View>


        </View >
      </View >
    </View >
  )
}
