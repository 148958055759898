import { atom, selector, selectorFamily } from "recoil";
import { currentMadLadAtom } from "./currentMadladAtom";
import {
  StreamflowSolana,
  Types,
  getNumberFromBN,
} from "@streamflow/stream";
import { pubkeyAtom } from "./userNftsAtom";
import { STREAMFLOW_PROGRAM_ID, VESTING_TOKEN_DECIMALS, wormholeDropStreams } from "../_participants/wormholeDropStreams";

export const streamFlowClientAtom = selector<StreamflowSolana.SolanaStreamClient>({
  key: "streamFlowClientAtom",
  get: async ({ get }) => {
    const pubkey = get(pubkeyAtom);
    if (!pubkey) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const rpcUrl = window.xnft?.solana?.connection?._rpcEndpoint;
    return new StreamflowSolana.SolanaStreamClient(
      rpcUrl,
      undefined,
      undefined,
      STREAMFLOW_PROGRAM_ID
    );
  }
});

export const refetchStreamAtom = atom({
  key: "refetchStreamAtom",
  default: ""
});

export const wormholeStreamIdForLad = selectorFamily<string | null, string>({
  key: "wormholeStreamIdForLad",
  get: (ladMint) => async ({ get }) => {
    const stream = wormholeDropStreams[ladMint];
    return stream?.streamId ?? null;
  }
});

const streamCache: {
  [vestingAccount: string]: {
    ttl: number,
    stream: Types.Stream,
    refetchAtom: string
  }
} = {};
export const wormholeStreamAtom = selector<Types.Stream>({
  key: "wormholeStreamAtom",
  get: async ({ get }) => {
    const streamFlowClient = get(streamFlowClientAtom);
    const currentLad = get(currentMadLadAtom);
    if (!streamFlowClient) {
      return null;
    }
    const refetchAtom = get(refetchStreamAtom);
    const vestingAccount = get(wormholeStreamIdForLad(currentLad.account.mint));

    if (
      streamCache[vestingAccount]?.ttl > 0 &&
      Date.now() >= streamCache[vestingAccount]?.ttl &&
      refetchAtom === streamCache[vestingAccount].refetchAtom
    ) {
      return streamCache[vestingAccount].stream;
    }

    const stream = await streamFlowClient.getOne({ id: vestingAccount });
    const nowSec = Math.floor(Date.now() / 1000)

    const secTillNext = stream.cliff > nowSec ?
      stream.cliff - nowSec :
      stream.period - ((nowSec - stream.start) % stream.period);

    streamCache[vestingAccount] = {
      ttl: Date.now() + (secTillNext * 1000),
      stream,
      refetchAtom
    };

    console.log(stream);

    return stream;
  }
});

type WormholeVestingAccount = {
  id: string,
  claimable: number,
  secondsToNext: number,
  claimableNext: number,
  totalAllocation: number,
  totalClaimed: number,
  start: Date,
  end: Date,
}

export const wormholeVestingAccountAtom = atom<WormholeVestingAccount | null>({
  key: "wormholeVestingAccountAtom",
  effects: [({ setSelf, getPromise }) => {
    setSelf(null);

    const refresh = () => {
      getPromise(wormholeStreamAtom).then(stream => {
        if (!stream) {
          return null;
        }
        const start = new Date(stream.start * 1000)
        const end = new Date(stream.end * 1000)
        const nowSec = Math.floor(Date.now() / 1000)

        const secTillNext = stream.cliff > nowSec ? stream.cliff - nowSec : stream.period - ((nowSec - stream.start) % stream.period);
        const claimable = getNumberFromBN(stream.unlocked(Date.now() / 1000), VESTING_TOKEN_DECIMALS) - getNumberFromBN(stream.withdrawnAmount, VESTING_TOKEN_DECIMALS);
        setSelf({
          id: stream.mint,
          claimable: parseFloat(claimable.toFixed(6)),
          secondsToNext: secTillNext,
          claimableNext: parseFloat(getNumberFromBN(stream.amountPerPeriod, VESTING_TOKEN_DECIMALS).toFixed(6)),
          totalAllocation: parseFloat(getNumberFromBN(stream.depositedAmount, VESTING_TOKEN_DECIMALS).toFixed(6)),
          totalClaimed: parseFloat(getNumberFromBN(stream.withdrawnAmount, VESTING_TOKEN_DECIMALS).toFixed(6)),
          start,
          end
        });

        setTimeout(() => requestAnimationFrame(refresh), 1000);

      })
    };

    refresh();
  }]
});