import { useEffect, useRef, useState } from "react";
import { Animated, Easing, FlatList, ImageBackground, Pressable, Text, View } from "react-native";
import { PublicKey } from "@solana/web3.js";
import { useRecoilState, useRecoilValue } from "recoil";
import { MaterialIcons } from "@expo/vector-icons";
import { currentMadLadAtom } from "../_atoms/currentMadladAtom";
import {
 getStakeApi
} from "../_utils/stakeApi";
import * as buffer from "buffer";
import { goldBalanceAtom } from "../_atoms/goldAtom";
import { isStakedAtom } from "../_atoms/stakeAtom";
import { pubkeyAtom } from "../_atoms/userNftsAtom";
import { confirmTxSignature } from "../_utils/confirmTxSignature";
import React from "react";
window.Buffer = buffer.Buffer;

export function Staking() {
  const currentMadLad = useRecoilValue(currentMadLadAtom);
  const publicKey = useRecoilValue(pubkeyAtom)
  const gold = useRecoilValue(goldBalanceAtom);
  const [isUserStaked, setUserIsStaked] = useRecoilState(isStakedAtom);
  const [isConfirming, setIsConfirming] = useState(false);

  const user = new PublicKey(publicKey);
  const nft = {
    mintAddress: new PublicKey(currentMadLad.account.mint),
    metadataAddress: new PublicKey(currentMadLad.publicKey),
  };

  const onStakeAction = async (_isUserStaked: boolean) => {
    const stakeApi = await getStakeApi();
    const action = _isUserStaked ? stakeApi.unstake.bind(stakeApi) : stakeApi.stake.bind(stakeApi);
    setIsConfirming(true);
    action(({ user, nft }))
      // wait for finalized so gold counter stops when loading ends.
      .then((tx) => confirmTxSignature(tx, "finalized"))
      .then((tx) => {
        setUserIsStaked(!_isUserStaked);
        setIsConfirming(false)
        console.log("tx signature", tx);
      })
      .catch((err) => {
        console.log(err)
        setIsConfirming(false)
      })
  };

  return (
    <View className="flex-row justify-between items-center font-inter rounded-3xl text-white p-3 pl-4 mx-4 bg-black bg-opacity-10 backdrop-blur-2xl">
      <Text className="text-white">
        {gold !== null && (
          renderGold(gold)
        )}
      </Text>
      <Pressable
        onPress={() => onStakeAction(isUserStaked)}
        className="hover:bg-opacity-20 flex-row justify-center items-center -m-[5px] bg-white bg-opacity-30 backdrop-blur-2xl rounded-3xl px-2"
      >
        <Text className="p-2 text-white">
          {isUserStaked === null
            ? "Loading"
            : isConfirming && isUserStaked
              ? "Unstaking"
              : isConfirming && !isUserStaked
                ? "Staking"
                : isUserStaked
                  ? "Unstake"
                  : "Stake"}
        </Text>
        {isUserStaked === null || isConfirming && (
          <View
            className="animate-spin relative"
          >
            <MaterialIcons
              name="autorenew"
              size={22}
              color="white"
            />
          </View>
        )}
      </Pressable>
    </View>
  );
}


function renderGold(balance: number) {
  const grouped = balance.toString().match(/(\d+?)(?=(\d{2})+(?!\d)|$)/g);

  const [dust, copper, silver, gold, ...diamondsArr] = grouped.reverse().filter(Boolean);
  const diamonds = diamondsArr.join("");

  return <View className="flex-row">
    {balance > 100000000 ? (
      <View className="mr-3 flex-row gap-1 justify-center items-center">
        <Text className=" min-w-[0.6rem] font-bold inline-block">{diamonds}</Text>
        <View className="rounded-full bg-[lightblue] w-3 h-3"></View>
      </View>) : null
    }
    {balance > 1000000 ? (
      <View className="mr-3 flex-row gap-1 justify-center items-center">
        <Text className=" min-w-[1.2rem] font-bold inline-block">{leftPad(gold)}</Text>
        <View className="rounded-full bg-[gold] w-3 h-3"></View>
      </View>) : null
    }
    {balance > 10000 ? (
      <View className="mr-3 flex-row gap-1 justify-center items-center">
        <Text className=" min-w-[1.2rem] font-bold inline-block">{leftPad(silver)}</Text>
        <View className="rounded-full bg-[lightgrey] w-3 h-3"></View>
      </View>) : null
    }
    {balance > 100 ? (
      <View className="mr-3 flex-row gap-1 justify-center items-center">
        <Text className="min-w-[1.2rem] font-bold inline-block">{leftPad(copper)}</Text>
        <View className=" rounded-full bg-[#B97333] w-3 h-3"></View>
      </View>) : null
    }
    {balance > 0 ? (
      <View className="flex-row justify-center items-center">
        <Text className="min-w-[1.2rem] gap-1 relative inline-block top-[0.5px] text-[12px]">{leftPad(dust)}</Text>
        <View className="top-[0.5px] relative rounded-full ring-[#B97333] ring-1 w-2 h-2"></View>
      </View>
    ) : (
      <View className="mr-3 flex-row justify-center items-center">
        <Text className="mr-1 min-w-[1.2rem] inline-block">{"Start staking to earn rewards!"}</Text>
      </View>
    )}
  </View>

}

const leftPad = (str: string|undefined) => ("00" + (str ?? "")).slice(-2);
