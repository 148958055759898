import { StatusBar } from 'expo-status-bar';
import { ImageBackground, Text, View } from 'react-native';
import { currentMadLadAtom } from '../_atoms/currentMadladAtom';
import { useRecoilValue } from 'recoil';
import { ScreenProps } from 'react-native-screens';


export default function DropzoneScreen({ navigation }) {
  const currentMadLad = useRecoilValue(currentMadLadAtom);

  return (
    <View className="justify-center top-[100px] w-full items-center flex">
      <Text className='font-inter-black'>{"Open up App.js to start working on your app!"}</Text>
      <StatusBar style="auto" />
    </View>
  )
}
