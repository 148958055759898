export const userPubkeyPromise = new Promise<string|null>((resolve) => {
  let counter = 0;

  if(typeof window === "undefined") {
    return resolve(null);
  }
  const interval = setInterval(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const pubkey = window?.xnft?.solana?.publicKey?.toString();

    if (pubkey) {
      clearInterval(interval)
      return resolve(pubkey);
    }
    if (counter > 100) {
      clearInterval(interval);
      console.error("No connected pubkey found");
      return resolve(null);
    }
    counter++;
  }, 200)
})
