import "./global.css";
import Navigator from './src/Navigator';
import { useFonts, Inter_900Black, Inter_400Regular, Inter_600SemiBold } from '@expo-google-fonts/inter';
import { Oswald_400Regular } from '@expo-google-fonts/oswald';
import { NavigationContainer } from "@react-navigation/native";
import { RecoilRoot } from "recoil";

export default function App() {
  let [fontsLoaded] = useFonts({
    Inter_400Regular,
    Oswald_400Regular,
    Inter_900Black,
    Inter_600SemiBold,
  });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <RecoilRoot>
      <NavigationContainer>
        <Navigator />
      </NavigationContainer>
    </RecoilRoot>
  );
}