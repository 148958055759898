import { Text, Pressable, View, Animated, GestureResponderEvent } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';


export function NavButton({
  onPress,
  disabled,
  active
}: {
  onPress: (e: GestureResponderEvent) => void;
  disabled?: boolean
  active?: boolean
}) {
  const iconColor = active ? "blue" : disabled ? "grey" : "black"
  return (
    <Pressable
      disabled
      onPress={onPress}
      className={`${disabled ? "opacity-50":""} relative right-[88px] top-[-3px] rounded-full bg-white flex items-center justify-center h-[32px] w-[32px]`}
    >
      <FontAwesome5 name="parachute-box" size={16} color={iconColor} />
    </Pressable>
  );

}