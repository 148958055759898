import { atom, selector } from "recoil";
import { pubkeyAtom } from "./userNftsAtom";
import { currentMadLadAtom } from "./currentMadladAtom";
import { getStakeApi } from "../_utils/stakeApi";
import { PublicKey } from "@solana/web3.js";

export const isStakedAtom = atom<boolean>({
  key: "isStakedAtom",
  default: selector<boolean>({
    key: "isStakedAtomDefault",
    get: async ({ get }) => {
      const publicKey = get(pubkeyAtom);
      const currentMadLad = get(currentMadLadAtom);
      const stakeApi = await getStakeApi();
      
      return stakeApi.isStaked({
        user: new PublicKey(publicKey),
        nft: {
          mintAddress: new PublicKey(currentMadLad.account.mint),
          metadataAddress: new PublicKey(currentMadLad.publicKey),
        },
      });
    }
  })
});