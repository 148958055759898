import { StatusBar } from 'expo-status-bar';
import { ImageBackground, Text, View } from 'react-native';
import { currentMadLadAtom } from '../_atoms/currentMadladAtom';
import { useRecoilValue } from 'recoil';
import { ScreenProps } from 'react-native-screens';
import React from 'react';

// @ts-ignore
import backpackLaunchBadge from "../../assets/MadLadsv2.mp4";

export default function RickRollScreen({ navigation }) {
  return (
    <View className="justify-center top-[0px] w-full h-full items-center flex bg-black">
      <video autoPlay loop controls={false} style={{ background: "black" }} width="100%" height="100%">
        <source
          type="video/mp4"
          src={backpackLaunchBadge}
        />
      </video>
    </View>
  )
}
