import { atom, selector } from "recoil";
// import { Infer, object, string, boolean, nullable, array, type, optional } from "superstruct";
import { userPubkeyPromise } from "../_utils/userPubkey";
import Constants from "expo-constants";

const MADLADS_COLLECTION = Constants.expoConfig.extra?.MADLADS_COLLECTION;

if (!MADLADS_COLLECTION) {
  throw "Missing env MADLADS_COLLECTION"
}

// export type UserNftAtomType = Infer<typeof UserNftAtomType>;
// export const UserNftAtomType = object({
//   publicKey: string(),
//   account: type({
//     collection: nullable(object({
//       key: string(),
//       verified: boolean()
//     })),
//     mint: string(),
//     data: type({
//       name: string(),
//       symbol: string(),
//       uri: string()
//     })
//   })
// });

export type UserNftAtomType = {
  publicKey: string,
  account: {
    collection?: {
      key: string,
      verified: boolean
    },
    mint: string,
    data: {
      name: string,
      symbol: string,
      uri: string
    }
  }
};

// export type UserNftsAtomType = Infer<typeof UserNftsAtomType>;
// export const UserNftsAtomType = array(nullable(UserNftAtomType));

export const pubkeyAtom = selector<string | null>({
  key: "recipient",
  get: async ({ get }) => {
    return userPubkeyPromise;
  }
});


export const userNftsAtom = selector<UserNftAtomType[] | null>({
  key: "userNftsAtom",
  get: async ({ get }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment

    const pubkey = await userPubkeyPromise;
    if (!pubkey) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // console.log("pca", pubkey, window.xnft?.connection);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment


    // @ts-ignore
    const customTokenAccounts = await window?.xnft?.connection?.customSplTokenAccounts?.(pubkey);
    // if (!UserNftsAtomType.is(customTokenAccounts?.nfts?.nftTokenMetadata)) {
    //   console.error(JSON.stringify(UserNftsAtomType.validate(customTokenAccounts?.nfts?.nftTokenMetadata)[0]));
    //   return null;
    // }
    // console.log("nfts", customTokenAccounts);

    return customTokenAccounts?.nfts?.nftTokenMetadata
  }
});

export const madNftsAtom = selector<UserNftAtomType[] | null>({
  key: "madNftsAtom",
  get: async ({ get }) => {
    const nfts = await get(userNftsAtom);

    if (!nfts) {
      return null;
    }

    return nfts.filter((nft) => nft?.account?.collection?.key === MADLADS_COLLECTION)
  }
});
