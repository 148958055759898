import { Text, View } from "react-native"


export default function UnknownUser() {
  return (
    <View
      className="h-full w-full flex justify-center items-center bg-black bg-opacity-40 backdrop-blur-md"
    >
      <Text
        className="font-inter-bold text-xl px-4 py-4 text-white"
      >
        Hey anon
      </Text>
      <Text
        className="font-inter-bold text-sm px-4 text-white"
      >
        Open this xNFT with your Backpack wallet!
      </Text>
    </View>
  )
}
