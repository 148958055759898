import { FlatList, Image, ImageBackground, Pressable, Text, View } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { currentMadLadAtom } from '../_atoms/currentMadladAtom';
import { useRecoilValue } from 'recoil';
import { ScrollView } from '../Components/ScrollView/ScrollView.web';
import { useState } from 'react';
import { Staking } from '../Components/Staking';
import { backpackLaunchParticipants } from '../_participants/backpackLaunchParticipants';
import { pythiaDropParticipants } from '../_participants/pythiaDropParticipants';
// @ts-ignore
import pythiaBadge from "../../assets/badges/PythiaV5.png"
// @ts-ignore
import backpackLaunchBadge from "../../assets/badges/BPDropV4.png"
// @ts-ignore
import wormholeDropBadge from "../../assets/badges/WormholeBadgeV1.png"
import { wormholeDropParticipants } from '../_participants/wormholeDropParticipants';
// @ts-ignore
import tensorRaffleBadge from "../../assets/badges/TensorRaffleBadge.png"
// @ts-ignore
import rrWLBadge from "../../assets/badges/RRWL.png"
import { tensorRaffleParticipants } from "../_participants/tensorRaffleParticipants"
import { wormholeStreamIdForLad } from '../_atoms/wormholeVestingAccountAtom';

type Badge = {
  uri: string,
  title: string,
  description: string,
  recipients: number,
  date: string,
  onPress?: () => void,
};

const skulls = [
  "9r19whEpS9FkqaEHY2NkV17VEzaLmzvKWPLFBjSZQYpH",
  "fBvgesYn3bYBgPR3GruBNV6VY926WK7iaMTapXEhS3C",
  "KqdCKLaENNWXcv9eHA99rt8n7ujETkEY93k5ezemZ7w",
  "2MfKQM4Sewgz5s9dEZv7LETXijuCV7TLDbTM6jW3yQmR",
  "FdsGZfG3dVx2Lbb2cdhNjoU91AsYBMqoHDhj3MkvCU66",
  "Haw5kGDkb9Hr25y97yXeTAt8KfheoEGC588Q9oDaGzc7",
  "G8FJmQzBbuem24DJUYeGruPmv1DHngEGkdd7YzawCwaU",
  "HWnkRFcFpmX1rDwYhLiAp7KfKCGy3eeu2KissbhYAYZb",
  "GpJBBjWD6cghyHJZymSqugSS22WN8iUHuaqTaJgX33GA",
  "HaiZuktgi6ZJULTZQLDXASAJpNHSpcgSWFAiSryfPmmG",
  "B6HNzu48szTJ8iut78k12eP7XFhuhcejFxj3DcQhZYaC",
  "CKPH2TxzciARSCkPcg6BVDNNA4cCHsm698tqjQEZQGNb",
  "5hEdMM5t4wvx8qRENKrCfV2iCk5vKknmDSEUEBc5DAs",
  "E2wUFPLyXybjsp58oR3LKCGvmx9fT79BobYLokGWJELz",
  "HmfpmjsYGnBfY6qpSHZbU28aiWYP34t5VB78HQLougtx",
  "HYWcvT8y8LMfXWgRXb1FRTu6sBP7yCFRZssv4rxAnpde",
  "9CbgZCn5SKxZWHG21EzhuMYUfc65T4ZXQXamZiiwTbvv",
  "D5VorFFtbAgmGafXdjzrod2HfFiocHjFmEzhQSH2Rh6t",
  "5sAb3o6Tsx4B4rbfUPzaii33acyhtU7KSJxV6fwJ1P3s",
  "Fzp15ELGCFYy8k7ns4k2b3Y5MhpmPeK6ppb3HLookBV7",
  "2ytEqsh9XECRtqcMm5qg2xL1RMJdFV1ry7xKVP66n1sL",
  "9PoCvN7rivdRQz7brZk3erfZ2CdeTSCVqjCgryBbF87e",
  "5knzAaPTuezWXqoffkUGTL46MyTnhaVwJAzBBs3ZXBfn",
  "8MXageyT6qBrDbSAizorPa3GgmrAjAf5B5SzvygqDYGc",
  "EfHHab9b4mbP4xd6b5C8jNppWUUr7bptup2yLNNaBjNt",
  "5ja3EvVuEu5rXgtYE3LXKG84s7Pmy5siFfYbcopMc2Dx",
  "AdRFcTX6gKP7f1catvHfU8SVZzRqMV9RGotCBzKfTPx4",
  "HRyLwyjDqHVCBUtiwpbWa4uVoucH8CauR7KzM3kQjHkL",
  "ESNFuinsGTQcgMKw2BMue1pTwWMKooqmfrGJZevFVji",
  "F5UBwfT1knPfkETDqbdG1iKXkF9jRQCqzzDuQnKVpTZJ",
  "AVLGjRAowAgPJ7gk1cRT5UxMWsm6uXg9Rs7pxaYzpVpF",
  "FwZqruGzoh4oQXbo1pgtJ8gTEgGRHqm4Ko89WdxxH2Df",
  "C5y13Yg3Wyrnf19rNTm9BmPN7PSjRJfEHNXHbFPPze7C",
  "DUp9W2Ad5yPTHeRpwE2L6juuTuLMsjUzeSKVamT9CDXv",
  "H9Z3EbQGrDo4QuTS3RGrYTtrcQGqiUcQnpkQGV3W4zTV",
  "9rP5pNSzg37jJfi5ppyWyn8tnsXAYHAUE8Q2MwtQYDXi",
  "WEo2gmmaBsfYd47VJ9C3h8d7LhxYqiqjJqAATLv419g",
  "EDNZkEwHcnCgf3aHpwskggnP2XCvBo4AHEmjZLPykZCm",
  "7dkWojqMZjZ4kRYvLgHNemHRHzLzpEFAsSKitgP6iXEM",
  "FXYFchuewPiwnzTDU3SGLh4QGZjtXTWcJKmjfocZoRTz",
  "EADMEm3WjAYukyagfnAHTT7L37kjozLDSNENjvSm7fp7",
  "4153oJAFhqRZFfFUwwSiF1jASs5BPTmPa56xGXQoQLMV",
  "HrC7EXPi3AkD38hCkRztfpGJ9o2pRb8ZnAQ2M8KmHih8",
  "4KnNU61JGKdmpv5MQLko7pg3kVpqgjyA4JbaJByU8x4r",
  "DC22H6bAshuJjmXXpyS8MUKGE7dPzW4FfqJT4dFBNZJp",
  "5UdcebtAd4TULnvAC6vNRbkd2knGh6JhbV88NKsguxrH",
  "Fw8YYtyLwEKcjQtzPbtZHwuQEgNorB6CCZDGPpVreA6U",
  "5K9dGYAPao5ySagKcHYKTBq1ko2Hvamd8bDvYH4NqPYQ",
  "1ZrnvELdCpzda8qK9bE7Hxco6aYkZAn9hmHgbaNj3Jz",
  "BYQXm87spJDQUQvp9FmYsvYvAm33MrjZWmc2F45YVwRU",
  "3aT4QZd8BQR8n8YZgcXAxCc5Gktye7eZxayU85wHcTF5",
  "2wArabgGHrXtaQqS5ZQFrddWyEWtAL6A11DHe4VZvSz5",
  "3bn4LpnUL4W5snNMtt94FLEGzdfmts4qn3Jz5HkBLEJ7",
  "6kfJgJYdtPL1LfRCXh77VbLfSRMKAQMUDy9A8i55VqZv",
  "EBN6B5iK1poV1pqZSZnfYRRUBfRwQu8PjQ8UdfBdxvmM",
  "AhpaJGp2sWjQEEz8JTJ21Mro83jcAaBmPpCekDxxdAoT",
  "2Ytx3Qp5Qev3S93T718fmpAK8ZVPBThyocwEpfziSsrp",
  "CMHNwcDZuTyKu5qZtgNqVsGuTvx7Ey5XNUHz6NjLmDwf",
  "DXQjWMVzbhGunEJGeHK1DbPKEdbNz2yLvs69nYfxfPSJ",
  "5TGVpYrmje5sYyf9nRUHmML2VUEaNTsTC24cz53FbxNA",
  "mRqT94K6oo3T3795XigTwzBGLLND4sjvzfsdv4PR3Dt",
  "13fkSUEvM4VEJBuX6No3PdDA4ahYpWeJgGD8a7bwWM8Q",
  "FkryfWhjF9knHx3rUV357geZJLkwpCP8K3af49zdcoHL",
  "HfFwS5gLcBJvawgCzNTKKBeMrqND4gCEpJMhJEbSMYtz",
  "Dwc7Mm9r13Czb2kdoYnC6NDCsZ8XKrpow2dq8mnHeyU1",
  "8wsLSdEMc3jMatLkU8Jr3nphpx62Km6m3FFNyGanafnW",
  "9RTEDULPG81crKHao3LWFuF7PjhFneVXDWtSq7UQXYdw",
  "GtfaHTSodBZ8je4BZW3ket8B18Zvxwwq2U2GbbuyQWEq",
  "3M9UdJnCsrYvBuCLLB5LFcGL44wbdghSHSUbHFbJ1zFm",
  "4ybsDZvURgL2nYMvpFQUQckqPQ7QT1fju5oVRyMKQqZc",
  "CXNLZncJJ7btw6LP84sWgzduamtebejWwSZmWPhW6vVc",
  "E15vXEstzUrv26wkLvkLy8bpCo9nB8kjYZPb8tUJ5yZW",
  "7j8Wt6XEHCzsYW5AVTAU6twju3tt9xXSu6yGTy1Vnwvz",
  "6xQmEMRTHacZ9RZnNd9ZRozdLsgDC2NC9yKVQG17QhGX",
  "3AmHkCWTmwHCcYsuagfPf2VRk8QLCgbcbh9fqQHVUnTe",
  "7fCC6TzAV58svkfKxzX1sPAvQAD6gKQpaDjbFSbSGo2f",
  "4hAvgcmDdYiEyXn5gJifPbp8Hc1jMXy2QSFdfxGzh2TZ",
  "5Yvam4HipCwxdyMVYsfNgPYhtTA1svCaep2TQdD3B5zE",
  "81foUy8EZ3zFaZ2s7dAWjmzKHsdmsHp1ghf4BPt4wv77",
  "JDtdTLzb7gpBBsQrKWDYX2NCfUZHkzATH2QX5dFrTL7H",
  "3p1dGyvbCMiA8pAncAaMadBFuzkL4X4pxfYsrKdCRjpC",
  "2UH62SurvrRHTtEAr2aKC42nw4BN87uTTf8289c3zAvz",
  "J4cHp4y4VGE8R2mf8r6YeJowo4oKjMZK6wvkyCWQZfAV",
  "kqvb9REWTiNtsbqRd2BQ6hP3b2jVG7y8L1dCZtAqTGR",
  "2Caf1k2CSp1yDRb5YpXGWD6vHPRGzvqdA8f3t8fbG4kA",
  "F5Us8HsZoWLwfvDoGGJWvW2G5JdgyLw87ssrbrFYbhNn",
  "CypccztsyD8gHGYLB2BBDQNCH5MsHkRoTjERWad71rwW",
  "BKfGgoRx1u1zw4Gc1NbvcsdvL12Zhxq4KCCWeQKxP6RM",
  "BgnCeuZbyGkptFSzPrP2RVxaK58j2WJWPj1uDf4CxNN7",
  "694En1yPMLibpzF2ZXToJoFEkLAVTFh2787iReryEutF",
  "7yAXKRNaxS1cnZs2hFRYieLTtbAu336bfkGnyZ1Lr8N2",
  "2XumnV79nikK1WVGQCQuRH8iCVX6eWfPAijhZVWVCCq5",
  "7b3JLBQAFhi2hARW4JabXq6pJakXoQATnfaiPyBGajih",
  "HJ5igKjFSNqDWUwyvLxM96yUeo3ZpkURkzNmFXdUBd2h",
  "J87H6aa37YqXWcBJ184KxM22jdz1Sc4aGyQbNxoq3wtr",
  "FADSDuHnwNJM7ZjFBTfX92h85RwA4fyZ5h7PgsCYT9Wg",
  "DQR6FhoBdRSzM9rLNtAaZ3yrttzvWy5w9twGJEPvUjbQ",
  "HrNukt8VeBCVxinbYP571Vhy36ehGgfwnLtVLZqsE5yV",
  "75GM9UAvB393ZAu9QrWgm2Wu4YySQEVVsWmwwdrpzqfg"
];

export default function InventoryScreen({ navigation }) {
  const currentMadLad = useRecoilValue(currentMadLadAtom);
  const [showBadgeOverlay, setBadgeOverlay] = useState<Badge | null>(null)
  const ladBadges: Array<Badge | null> = [];
  const isBackpackLaunchParticiant = backpackLaunchParticipants.includes(currentMadLad.account.mint);
  const isPythiaParticipant = pythiaDropParticipants.includes(currentMadLad.account.mint);
  const wormholeStreamId = useRecoilValue(wormholeStreamIdForLad(currentMadLad.account.mint));
  const isWormholeDropParticipant = [
    "4B18U9PqtKFEwsHnZECyRewmh4zrFUo1Pxyf3Lwf1EMq",
    ...wormholeDropParticipants].includes(currentMadLad.account.mint) || wormholeStreamId;

  const isTensorRaffleParticipant = tensorRaffleParticipants.includes(currentMadLad.account.mint);

  const isSkull = skulls.includes(currentMadLad.account.mint);

  const wormholeDropBadgeDescription = isSkull ?
    "This Wormhole badge awards you 20,000 $W. 4000 $W unlocked and 16,000 $W vesting daily for a year. Check back soon." :
    "This Wormhole badge awards you 16,000 $W. 3200 $W unlocked and 12,800 $W vesting daily for a year. Check back soon.";

  const badges: { [id: string]: Badge } = {
    backpackLaunchBadge: {
      uri: backpackLaunchBadge,
      title: "Pyth Drop Event",
      description: "Awarded for participating in the Backpack Exchange launch.",
      recipients: backpackLaunchParticipants.length,
      date: "Nov 20, 2023"
    },
    pythiaBadge: {
      uri: pythiaBadge,
      title: "Beseech the Oracle Event",
      description: "Awarded for participating in the Pythia 1:1 raffle.",
      recipients: pythiaDropParticipants.length,
      date: "Dec 1, 2023"
    },
    wormholeDropBadge: {
      uri: wormholeDropBadge,
      onPress: wormholeStreamId ? () => {
        navigation.push("Wormhole")
      } : undefined,
      title: "Wormhole (W)",
      description: wormholeDropBadgeDescription,
      recipients: null, //wormholeDropParticipants.length,
      date: "Feb 8, 2024"
    },
    tensorRaffleBadge: {
      uri: tensorRaffleBadge,
      title: "Tensor Glitch",
      description: "Awarded for participating in the Tensor 1:1 raffle.",
      recipients: tensorRaffleParticipants.length,
      date: "Feb 12, 2024"
    },
    rickRollBadge: {
      uri: rrWLBadge,
      onPress: () => {
        navigation.push("RickRollScreen")
      },
      title: "WL",
      description: "RR",
      recipients: 10000,
      date: "Feb 21, 2024"
    }
  }

  if (isBackpackLaunchParticiant) {
    ladBadges.push(badges.backpackLaunchBadge);
  }
  if (isPythiaParticipant) {
    ladBadges.push(badges.pythiaBadge);
  }
  if (isWormholeDropParticipant) {
    ladBadges.push(badges.wormholeDropBadge);
  }
  if (isTensorRaffleParticipant) {
    ladBadges.push(badges.tensorRaffleBadge);
  }
  ladBadges.push(badges.rickRollBadge);

  // fill up row if necessary
  if (ladBadges.length === 0 || ladBadges.length % 3 > 0) {
    ladBadges.push(...(new Array(3 - ladBadges.length % 3).fill(null)))
  }

  return (
    <ImageBackground
      // @ts-ignore
      className={`w-full h-full`}
      source={{ uri: `https://madlads.s3.us-west-2.amazonaws.com/images/${currentMadLad.id}.png` }}
      resizeMode="cover"
    >
      <ScrollView>
        <View className="block h-full z-0 -mb-[120px]"></View>
        <Staking />
        <View className={`m-4 grid gap-4 grid-cols-3 z-1`}>
          {ladBadges.map((badge, i) => (
            <Pressable
              key={badge?.description ?? i}
              className={`aspect-square rounded-3xl overflow-hidden text-white bg-black bg-opacity-10 backdrop-blur-2xl justify-center items-center ${badge ? "cursor-pointer" : ""}`}
              onPress={badge?.onPress ?? (() => {
                if (badge) {
                  setBadgeOverlay(badge)
                }
              })}
            >
              {badge ? (
                <Image
                  style={{
                    width: "100%",
                    height: "100%"
                  }}
                  source={{
                    uri: badge.uri
                  }}
                />
              ) : null}
            </Pressable>
          ))}
        </View>
      </ScrollView>
      {
        showBadgeOverlay ? (
          <Pressable
            className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-10 backdrop-blur-2xl justify-center items-center"
            onPress={() => {
              setBadgeOverlay(null);
            }}
          >
            <View className="w-[300px]">
              <Image
                className="rounded-3xl"
                style={{
                  marginBottom: 20
                }}
                source={{
                  height: 300,
                  width: 300,
                  uri: showBadgeOverlay.uri
                }}
              />
              <View className=" bg-black bg-opacity-10 backdrop-blur-2xl px-4 py-3 rounded-2xl">
                <Text className="text-white font-inter-bold text-lg">{`${showBadgeOverlay.title}`}</Text>
                <Text className="text-white font-inter-medium py-3">{`${showBadgeOverlay.description}`}</Text>
                <View className="flex-row">
                  <View className="w-[35%]">
                    <Text className="text-white  font-inter-medium text-xs">{`Acquired `}</Text>
                  </View>
                  <Text className="text-white font-inter-bold text-xs">{`${showBadgeOverlay.date}`}</Text>
                </View>
                {showBadgeOverlay.recipients ? (
                  <View className="flex-row">
                    <View className="w-[35%]">
                      <Text className="text-white  font-inter-medium text-xs">{`Recipients `}</Text>
                    </View>
                    <Text className="text-white font-inter-bold text-xs">{`${showBadgeOverlay.recipients}`}</Text>
                  </View>
                ) : null}
              </View>
            </View>
          </Pressable>
        ) : null
      }
    </ImageBackground >
  )
}
