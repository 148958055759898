
import { selector } from "recoil";

const restrictedCountries = [
  // "KR",  // remove Korea (only testing)
  "CU",
  "IR",
  "IQ",
  "KP",
  "RU",
  "SY",
  "UA",
  "GB",
  "US"
];

export const isRestrictedLocationAtom = selector<boolean>({
  key: "isRestrictedLocationAtom",
  get: async () => {
    const response = await fetch(`https://geo.backpack.workers.dev/`);
    const json = await response.json();
    return restrictedCountries.includes(json.country);
  }
});