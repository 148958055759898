import { atom, selector } from "recoil";
import { UserNftAtomType, madNftsAtom } from "./userNftsAtom";
import { getIndexFromMadladName } from "../_utils/getIndexFromMadladName";


// if (!process.env.NEXT_PUBLIC_REVEAL_METADATA_BASE_URL) {
//   throw "Missing env variable"
// }


export const currentMadLadAtom = selector<UserNftAtomType & { id: number } | null>({
  key: "currentMadLadAtom",
  get: async ({ get }) => {
    const madNfts = get(madNftsAtom);

    const currentLadAddress = window.location.hash.replace("#/", ""); 
    // const currentLadAddress = "4B18U9PqtKFEwsHnZECyRewmh4zrFUo1Pxyf3Lwf1EMq";
    // const currentLadAddress = "DL4pWLrfh2wXiovZLtbjeXDYMoo6zoa7wFCVJX8qUpxw";
    // const currentLadAddress = "3yYLEY3gPNHskKfyDZk3JRbDh7uSi1MKB3ffS9GEZXUJ";
    
    const currentLad = madNfts?.find(mint => mint?.account.mint === currentLadAddress);
    const currentLadId = getIndexFromMadladName(currentLad?.account.data.name ?? "");

    if (!currentLad || !currentLadId) {
      return null;
    }

    return {
      ...currentLad,
      id: parseInt(currentLadId)
    }
  }
});
