import { StackCardStyleInterpolator, createStackNavigator } from '@react-navigation/stack';

import { Ionicons } from '@expo/vector-icons';
import InventoryScreen from './screens/InventoryScreen';
import { Text, View, Animated, TouchableOpacity } from 'react-native';
import { useRecoilValue } from 'recoil';
import { currentMadLadAtom } from './_atoms/currentMadladAtom';
import DropzoneScreen from './screens/DropzoneScreen';
import { NavButton } from './Components/NavButton';
import UnknownUser from './Components/UnknownUser';
import RickRollScreen from './screens/RickRollScreen';
import WormholeAccountScreen from './screens/WormholeAccountScreen';

const Stack = createStackNavigator();

const CloseIcon = ({ onPress }) => (
  <TouchableOpacity onPress={onPress} style={{ marginLeft: 10 }}>
    <Ionicons name="close" size={28} color="white" />
  </TouchableOpacity>
);

export default function Navigator() {
  const currentMadLad = useRecoilValue(currentMadLadAtom);

  if (!currentMadLad) {
    return <UnknownUser />
  }

  return (
    <View className="w-full h-full flex justify-center items-stretch overflow-hidden">
      <Stack.Navigator
        // initialRouteName='Wormhole'
        screenOptions={{
          animationEnabled: true,
          cardStyleInterpolator: forSlideRight,
          headerTransparent: true,
          headerShadowVisible: false,
          headerTintColor: '#000',
          headerTitleStyle: {
            fontFamily: "Inter_400Regular",
            fontSize: 15
          },
        }}
      >
        <Stack.Screen
          name="Inventory"
          component={InventoryScreen}
          options={({ navigation, route }) => ({
            headerTitle: () => (
              <Text className="-mt-1 text-black font-inter-bold">{`Mad Lad #${currentMadLad.id}`}</Text>
            ),
            headerRight: () => (
              <NavButton
                disabled={true}
                onPress={() => { navigation.push("Dropzone") }}
              />
            ),
          })}
        />
        <Stack.Screen
          name="Dropzone"
          component={DropzoneScreen}
          options={({ navigation, route }) => ({
            headerTitle: () => (
              <Text className="-mt-1 text-black font-inter-bold">{`Dropzone`}</Text>
            ),
            headerRight: () => (
              <NavButton
                disabled={false}
                onPress={() => { navigation.push("Inventory") }}
              />
            ),

          })}
        />
        <Stack.Screen
          name="Wormhole"
          component={WormholeAccountScreen}
          options={({ navigation, route }) => ({
            presentation: "transparentModal",
            cardStyleInterpolator: forSlideBottom,
            headerTitle: () => (
              null
            ),
            headerLeft: ({ onPress }) => <CloseIcon onPress={onPress} />,
            headerLeftContainerStyle: { marginTop: "-5px" },
            // headerRight: () => (
            //   <NavButton
            //     disabled={false}
            //     onPress={() => { navigation.push("Inventory") }}
            //   />
            // ),

          })}
        />
        <Stack.Screen
          name="RickRollScreen"
          component={RickRollScreen}

          options={({ navigation, route }) => ({
            headerShown: false,
            // headerTitle:()=>(
            //   <Text className="-mt-1 text-black font-inter-bold">{`WL`}</Text>
            // ),
            // headerRight: () => (
            //   <NavButton
            //     disabled={false}
            //     onPress={() => { navigation.push("Inventory") }}
            //   />
            // ),

          })}
        />
      </Stack.Navigator>
    </View>
  );
}

const forSlideRight: StackCardStyleInterpolator = ({ current, next, inverted, layouts: { screen } }) => {
  const progress = Animated.add(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
    next
      ? next.progress.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
        extrapolate: 'clamp',
      })
      : 0
  );

  return {
    cardStyle: {
      transform: [
        {
          translateX: Animated.multiply(
            progress.interpolate({
              inputRange: [0, 1, 2],
              outputRange: [
                screen.width, // Focused, but offscreen in the beginning
                0, // Fully focused
                screen.width * -0.3, // Fully unfocused
              ],
              extrapolate: 'clamp',
            }),
            inverted
          ),
        },
      ],
    },
  };
};

const forSlideBottom: StackCardStyleInterpolator = ({ current, next, inverted, layouts: { screen } }) => {
  const progress = Animated.add(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
    next
      ? next.progress.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
        extrapolate: 'clamp',
      })
      : 0
  );

  return {
    cardStyle: {
      transform: [
        {
          translateY: Animated.multiply(
            progress.interpolate({
              inputRange: [0, 1, 2],
              outputRange: [
                screen.height, // Focused, but offscreen in the beginning
                0, // Fully focused
                screen.height * -0.3, // Fully unfocused
              ],
              extrapolate: 'clamp',
            }),
            inverted
          ),
        },
      ],
    },
  };
};
