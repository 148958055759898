import { Commitment } from "@solana/web3.js";

export const confirmTxSignature = (tx: string, committment:Commitment = "confirmed") => {

  // wait/poll for confirmation
  return new Promise((resolve, reject) => {
    (async () => {
      let confirmed = null
      let tries = 0;
      do {
        try {
          await new Promise((resolveDelay) => setTimeout(resolveDelay, 1500));
          // @ts-ignore
          confirmed = await window.xnft.solana.connection.getParsedTransaction(tx, committment);
          console.log(tx, confirmed)
        }
        catch (e) {
          console.error(e);
        }
        tries++;
      }
      while (!confirmed && tries < 60);

      if (confirmed) {
        resolve(tx);
      }
      else {
        reject(new Error("Could not confirm TX"))
      }
    })();
  });

}